//Mixins
.fixedWidthWrapper() {
	position: relative;
	width: 1084px;
	margin: 0 auto;
	@media (max-width: 1084px) {
		width: 100%;
	}
}

.box-sizing(@sizing: border-box) {
	box-sizing: @sizing;
}

.text-shadow(@color) {
	text-shadow: 0 0 1.5rem @color, 0 0 0.8rem @color, 0 0 0.6rem @color, 0 0 0.4rem @color;
}

.transition(@duration: 0.15s, @delay: 0s, @ease: ease, @property: all) {
	transition: @property @duration @ease @delay;
}

.transition-delay(@delay: 0s) {
	transition-delay: @delay;
}

.flex-container() {
	display: -webkit-box;
	/* OLD - iOS 6-, Safari 3.1-6 */
	display: -moz-box;
	/* OLD - Firefox 19- (buggy but mostly works) */
	display: -ms-flexbox;
	/* TWEENER - IE 10 */
	display: -webkit-flex;
	/* NEW - Chrome */
	display: flex;
	/* NEW, Spec - Opera 12.1, Firefox 20+ */
}

.flex(@grow: 1;
@shrink: 1;

@basis: auto) {
	-webkit-box-flex: @grow;
	/* OLD - iOS 6-, Safari 3.1-6 */
	-moz-box-flex: @grow;
	/* OLD - Firefox 19- */
	width: @basis;
	/* For old syntax, otherwise collapses. */
	-webkit-flex: @grow @shrink @basis;
	/* Chrome */
	-ms-flex: @grow @shrink @basis;
	/* IE 10 */
	flex: @grow @shrink @basis;
	/* NEW, Spec - Opera 12.1, Firefox 20+ */
}

.placeholder(@color) {
	::-webkit-input-placeholder {
		color: @color;
	}

	:-moz-placeholder {
		color: @color;
		opacity: 1;
	}

	::-moz-placeholder {
		color: @color;
		opacity: 1;
	}

	:-ms-input-placeholder {
		color: @color;
	}
}

.flex-justify(@justify: center) {
	-webkit-box-pack: @justify;
	-moz-box-pack: @justify;
	box-pack: @justify;
	-webkit-justify-content: @justify;
	justify-content: @justify;
}

.flex-centre() {
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.flex-column() {
	-webkit-box-orient: vertical;
	-moz-box-orient: vertical;
	box-orient: vertical;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.flex-wrap(@wrap: wrap) {
	-webkit-flex-wrap: @wrap;
	-moz-flex-wrap: @wrap;
	flex-wrap: @wrap;
}

.column-no-break() {
	-webkit-column-break-inside: avoid;
	-moz-column-break-inside: avoid;
	column-break-inside: avoid;
}

.column-width(@width) {
	-webkit-column-width: @width;
	-moz-column-width: @width;
	column-width: @width;
}

.column-gap(@gap: 0px) {
	-webkit-column-gap: @gap;
	-moz-column-gap: @gap;
	column-gap: @gap;
}

.greyscale() {
	filter: saturate(0);
}

.whiten() {
	filter: brightness(4);
}

.blacken() {
	filter: brightness(0);
}

.clearfix {
	&:after {
		content: '';
		display: block;
		clear: both;
		height: 0;
	}
}

.persistentAnimate(@arguments) {
	animation: @arguments;
	-webkit-animation: @arguments;
	-moz-animation: @arguments;
	animation-fill-mode: forwards;
	animation-timing-function: linear;
	-webkit-animation-fill-mode: forwards;
	-webkit-animation-timing-function: linear;
	-moz-animation-fill-mode: forwards;
	-moz-animation-timing-function: linear;
}

.animation-direction(@direction: alternate) {
	-webkit-animation-direction: @direction;
	-moz-animation-direction: @direction;
	animation-direction: @direction;
}

.animation-delay(@delay: 0s) {
	-webkit-animation-delay: @delay;
	-moz-animation-delay: @delay;
	animation-delay: @delay;
}

.animation-duration(@duration: 0s) {
	-webkit-animation-duration: @duration;
	-moz-animation-duration: @duration;
	animation-duration: @duration;
}

.animation-timing-function(@timing: 0s) {
	-webkit-animation-timing-function: @timing;
	-moz-animation-timing-function: @timing;
	animation-timing-function: @timing;
}

.fadeInAndOut(@interval: 2s, @duration: infinite) {
	-webkit-animation: fade @interval @duration;
	-moz-animation: fade @interval @duration;
	-o-animation: fade @interval @duration;
	animation: fade @interval @duration;
}

.flickerIn(@duration: 1s) {
	transition: none;
	animation: flickerIn @duration;
}

.fadeOpacity() {
	opacity: 0;
	transition: all 0.3s;

	&.fadeIn {
		opacity: 1;
	}
}

.growOnHover(@scale: 1.2, @initial: 1, @extra: ~'') {
	cursor: pointer;
	transition: all 0.15s;
	transform: scale(@initial) @extra;

	&:hover {
		transform: scale(@scale) @extra;
	}
}

.spinOnHover(@scale: 1.1, @rotation: 90deg) {
	cursor: pointer;
	transition: all 0.15s;

	&:hover {
		transform: rotate(@rotation) scale(@scale);
	}
}

.keyframes(@name, @arguments) {
	@-moz-keyframes @name {
		@arguments();
	}

	@-webkit-keyframes @name {
		@arguments();
	}

	@keyframes @name {
		@arguments();
	}
}

.keyframes(fade; {0% {opacity: 0;} 50% {opacity: 1;} 100% {opacity: 0;}});

.keyframes(flickerIn; {0% {opacity: 0;} 100% {opacity: 1;}});
