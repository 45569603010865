/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.VideoPlayer {
  width: 100%;
  height: 100%;
  position: relative;
}
.VideoPlayer:not(.mobile).fullscreen {
  width: 100vw;
  height: 100vh;
}
.VideoPlayer.fullscreen {
  left: 0;
  top: 0;
  position: fixed !important;
  z-index: 2000;
  background: black;
}
.VideoPlayer:hover .VideoPlayer__controls {
  opacity: 1;
}
.VideoPlayer.mobile .VideoPlayer__controls {
  opacity: 1;
}
.VideoPlayer__video {
  width: 100%;
  height: 100%;
}
.VideoPlayer__video iframe {
  width: 100%;
  height: 100%;
}
.VideoPlayer__errorBlanker {
  width: 100%;
  position: absolute;
  background: #2d272d;
  color: #fff;
  padding: 1rem;
}
.VideoPlayer__errorBlanker img {
  position: absolute;
  top: .8rem;
  right: .8rem;
  width: 1.6rem;
  height: 1.6rem;
  cursor: pointer;
}
.VideoPlayer__controls--flex {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.VideoPlayer__controls__icon {
  transition: all 0.15s;
  cursor: pointer;
  margin: 0 0.5rem;
}
.VideoPlayer__controls__icon:hover {
  transform: scale(1.1);
}
.VideoPlayer__controls__icon.flipped {
  transform: scaleY(-1);
}
.VideoPlayer__controls__icon.flipped:hover {
  transform: scale(1.1, -1.1);
}
.VideoPlayer__controls {
  opacity: 0;
  transition: 0.5s;
  padding: 1rem 0.5rem;
  position: absolute;
  bottom: 0;
  width: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #000000 100%);
}
.VideoPlayer__controls .ProgressBar {
  overflow: visible;
  height: 0.6rem;
  margin: 1rem 0.5rem;
}
.VideoPlayer__controls .ProgressBar-elapsed,
.VideoPlayer__controls .ProgressBar-value {
  background: #f9f6ef;
  border-radius: 0.6rem;
}
.VideoPlayer__controls .ProgressBar-seek {
  position: absolute;
  cursor: pointer;
}
.VideoPlayer__controls .ProgressBar-handle {
  position: absolute;
  width: 1.6rem;
  height: 1.6rem;
  background: #ff486b;
  border-radius: 100%;
  transform: scale(0);
  transition: transform 0.2s;
}
.VideoPlayer__controls .ProgressBar.isSeekable:hover .ProgressBar-handle,
.VideoPlayer__controls .ProgressBar.isEnabled:hover .ProgressBar-handle {
  transform: scale(1);
}
.VideoPlayer__controls .ProgressBar.isSeekable:hover .ProgressBar-intent,
.VideoPlayer__controls .ProgressBar.isEnabled:hover .ProgressBar-intent {
  position: absolute;
  background: rgba(0, 0, 0, 0.2);
}
.VideoPlayer__controls .ProgressBar.isSeekable .ProgressBar-elapsed,
.VideoPlayer__controls .ProgressBar.isEnabled .ProgressBar-elapsed {
  background: #ff486b;
}
.VideoPlayer__controls .ProgressBar-elapsed {
  height: 100%;
}
.VideoPlayer__controls .ProgressBar-seek {
  top: -0.8rem;
  right: 0;
  bottom: -0.8rem;
  left: 0;
}
.VideoPlayer__controls .ProgressBar-handle {
  top: 0;
  margin: -0.4rem 0 0 -0.8rem;
}
.VideoPlayer__controls .ProgressBar.isSeekable:hover .ProgressBar-handle {
  transform: scale(1);
}
.VideoPlayer__controls .ProgressBar.isSeekable:hover .ProgressBar-intent {
  top: 0;
  left: 0;
  height: 100%;
}
.VideoPlayer__controls__chapters {
  position: absolute;
  top: 2.1rem;
  left: 1rem;
  right: 1.5rem;
  height: 2rem;
  pointer-events: none;
}
.VideoPlayer__controls__chapters__chapterDot {
  width: 1.3rem;
  height: 1.3rem;
  margin-left: -0.5rem;
  color: #fff;
  text-align: center;
  font-size: 1.2rem;
  background: #d3063a;
  position: absolute;
  border-radius: 50%;
  top: -0.5rem;
  font-weight: bold;
}
.VideoPlayer__controls__right .TimeMarker {
  display: flex;
  color: #fff;
  font-weight: 600;
  margin-right: 0.5rem;
}
.VideoPlayer__controls__right,
.VideoPlayer__controls__playback,
.VideoPlayer__controls__volume {
  display: flex;
  align-items: center;
}
.VideoPlayer__controls__volume {
  width: 10rem;
  margin-top: -0.3rem;
}
.VideoPlayer__controls__volume:hover .ProgressBar {
  opacity: 1;
}
.VideoPlayer__controls__volume .ProgressBar.isSeekable {
  width: 5rem;
  opacity: 1;
  transition: all 0.5s;
}
.VideoPlayer__controls__volume .ProgressBar.isSeekable .ProgressBar-handle,
.VideoPlayer__controls__volume .ProgressBar.isSeekable .ProgressBar-elapsed {
  background: #4a82ff;
}
.VideoPlayer__controls__icon--muted {
  opacity: 0.2;
}
.VideoPlayer__controls__chapters__chapterDot__completed {
  background: #aae053;
}
.VideoPlayer__controls:fullscreen {
  z-index: 2147483648;
}
.VideoPlayer__controls:-webkit-full-screen {
  z-index: 2147483648;
}
.VideoPlayer__controls:-moz-full-screen {
  z-index: 2147483648;
}
.VideoPlayer__video:fullscreen {
  background-color: black;
}
.VideoPlayer__video:-webkit-full-screen {
  background-color: black;
}
.VideoPlayer__video:-moz-full-screen {
  background-color: black;
}
